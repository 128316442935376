import React, { useEffect, useState } from "react";
import MyPopup from "../popup/MyPopup";
import { useImageUploadContext } from "../../providers/ImageUploadProvider";
import { useAuth } from "../../providers/AuthProvider";

// Define a functional component named UploadAndDisplayImage
const UploadAndDisplayImage = () => {

  const [showing, setShowing] = useState(false)
  const {selectedImage, setSelectedImage, imageBase64, setImageBase64, setRefresh} = useImageUploadContext();
  const {token} = useAuth();

  const doUploadImage = (image) => {

    // convert image file to base64
    const reader = new FileReader()
    reader.readAsDataURL(image)

    // load the base64 reader, and use the result to trigger the useEffect, that will upload it
    reader.onload = () => {
      setImageBase64(reader.result)
    }
  }

  useEffect(() => {
    const uploadRequest = async (data) => {

      // upload image data as post request to my database endpoint
      const response = await fetch("https://cfw-database.jonnynaylor92.workers.dev/api/users/images", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              // Authorization: `Bearer 1312321`   // 13123213
          },
          body: JSON.stringify(data),
      });
          
      // get response json
      const res = await response.json();
      console.log("Upload attempt result: ", res)
      if(res !== true){
        alert("Failed to upload: ", {response})
        return
      }
  
      // update states
      setSelectedImage(null)
      // setShowing(true)
      setRefresh(true)
      alert("Upload successful!: ", {response})
  
    }

    // if image data is null, exit
    if(!imageBase64)
        return;

    // wrap the data in json packet to upload
    const data = {
        "filename": selectedImage.name,
        "base64": imageBase64
    }

    // do the actual upload
    uploadRequest(data)

  }, [selectedImage, token, imageBase64, setRefresh, setSelectedImage]) // - OUR DEPENDENCY, this useEffect triggers when 'imageBase64' changes

 

  // Return the JSX for rendering
  return (
    <div className="centered" style={{paddingBottom: 10}}>
        <MyPopup trigger={showing} setTrigger={setShowing}>
            LOL
        </MyPopup>
      <h2>Upload and Display Image</h2>

      {/* Conditionally render the selected image if it exists */}
      {selectedImage && (
        <div>
          {/* Display the selected image */}
          <img alt="not found" width={"250px"} src={URL.createObjectURL(selectedImage)}/>
          <br /> <br />
          {/* Button to remove the selected image */}
          <button onClick={() => setSelectedImage(null)}>Remove</button>
          <button onClick={() => doUploadImage(selectedImage)}>Upload</button>
        </div>
      )}

      

      <br />

      <input
        type="file"
        name="myImage"
        
        // Event handler to capture file selection and update the state
        onChange={(event) => {
          console.log(event.target.files[0]); // Log the selected file
          setSelectedImage(event.target.files[0]); // Update the state with the selected file
        }}
      />
    </div>
  );
};

// Export the UploadAndDisplayImage component as default
export default UploadAndDisplayImage;