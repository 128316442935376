import my_logo from '../../assets/my_logo.png';
import icon1 from '../../assets/icon1.png';
import icon2 from '../../assets/icon2.png';
import icon3 from '../../assets/icon3.png';
import { useEffect, useRef } from 'react';
import { useMyDropDownContext } from '../../providers/MyDropDownProvider';
import "./MyDropDownComponent.css"
import { useNavigate } from 'react-router-dom';

// my drop down menu list
const menu_list = [
  {
    title: 'Home',
    icon: icon1,
    path: "/"
  },
  {
    title: 'Work',
    icon: icon2,
    path: "/work"
  },
  {
    title: 'API',
    icon: icon3,
    path: "/dashboard"
  },
]

function MyDropDownComponent() {
  
  // navigate
  const navigate = useNavigate()
  const changeRoute = (path) => {
    console.log("NEW PATH")
    navigate(path);
  }

  // we want a useState to represent and set our drop down open state
  // const [open, setOpen] = useState(false);
  const { open, setOpen } = useMyDropDownContext();

  // we want to create a reference to one of our HTML objects
  // - specifically the  <div className="menu-container"> that contains all our dropdown elements
  // - this also includes the icon at the top that we click to toggle the dropdown items showing
  let ref_to_my_menu = useRef();

  // we want a use effect, that is triggered on ever re-render (so we don't include the [] dependency array)
  useEffect(() => {
    // a callback function to handle the mouse down event
    let myMouseDownCallback = (event) => {
      // console.log("My ref is: ", ref_to_my_menu)
      // console.log("Target is: ", event.target)
      if(!ref_to_my_menu.current.contains(event.target)){
        setOpen(false)
        console.log("Clicked out")
      }
    }

    // add a mouse down listener
    document.addEventListener("mousedown", myMouseDownCallback)

    // when the component is removed (e.g. we leave this page)
    // - we want to remove the mouse down event listener that we creatred
    return () => {
      document.removeEventListener("mousedown", myMouseDownCallback)
    }
  });


  const generateDropDownItems = () => {
    return menu_list.map((item) => {
      return <DropdownItem img={item.icon} title={item.title} onClick={() => {changeRoute(item.path)}} /> 
    });
  }

  // return the jsx markup
  // - our dropdown compopnent is represented by a <div> that with give the "menu-container" className to, this could be any name we want
  // - we can then referewnce it in our .css file to style it
  // - we also want to use the REFERENCE we created to refer to this menu (ref_to_my_menu), so we pass it as ref value

  // - there is a logo at the top, with an 'onClick' listener, that simply toggles the open state via the 'setOpen' function from our 'useState' hook

  // we also have a dropdown-menu div, that represents the whole menu that drops down
  // - we modify this div's className dynamically, if we are in the 'open' state we give it the 'active' class, otherwise, it has an 'inactive' class name
  // - we have a <h3> with some information
  // - and below, we have a <ul> list element, and we generate all the <li> list items via a function
  return (
    <div className="menu-container" ref={ref_to_my_menu}>
      <div className="menu-trigger" onClick={()=>setOpen(!open)}>
        <img src={my_logo} alt=""></img>
      </div>
      

      <div className={`dropdown-menu ${open ? 'active' : 'inactive'}`}>
        <h3>
          exodus
          <br/><br/>
          <span>Developer</span>
        </h3>
        <ul className={`dropdown-menu-ul`}>
          {generateDropDownItems()}
        </ul>    
      </div>
    </div>
  );
}



function DropdownItem(props){
  return (
    <li className='dropdownItem' onClick={props.onClick}>
      <img src={props.img} alt=""></img>
      <div className='dropdownItemText'>{props.title}</div>
    </li>
  )
}

export default MyDropDownComponent;
