
import { useEffect, useState } from "react"
import ImageDisplayComponent from "./ImageDisplayComponent";
import { useImageUploadContext } from "../../providers/ImageUploadProvider";

export default function ImageGalleryAPIComponent() {

    const [images, setImages] = useState([])
    const {refresh} = useImageUploadContext();

    // retch images
    const fetchImages = async () => {
    
        // login request to api endpoint
        const response = await fetch("https://cfw-database.jonnynaylor92.workers.dev/api/users/images");
            
        // get response json
        const res = await response.json();

        setImages(res)
        console.log("Got repsonse: ", res)
    }
    
    useEffect(() => {
        fetchImages()
    }, []) // -- fetch images on page refresh

    useEffect(() => {
        fetchImages()
    }, [refresh]) // -- fetch images if 'refresh' state is changed

    return (
        <>
            {/* <div>CATS </div> */}
            <hr></hr>
            <div className="image-display-container">
                { images.map((item) => {
                    return <ImageDisplayComponent img={`data:image/jpeg;${item.base64}`} />
                })}
            </div>
        </>
    );
}
