import { Link } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";

export default function DashboardPage(){
    const auth = useAuth();
    
    return (
        <>
        <div className="centered">
            <div>
                <h2>Welcome! {auth.user?.username}</h2>
                <p> Click on any of the sections below to browse, or logout at the bottom </p>
            </div>
            <div>
                <Link className='my-section-link' to="/dashboard/images"> Images </Link>
                <Link className='my-section-link' to="/dashboard/images"> Todo </Link>
                <Link className='my-section-link' to="/dashboard/images"> Images </Link>
            </div>
            
            <div style={{paddingTop: "2em"}}>
                <button onClick={() => auth.logOut()} className="btn-logout"> logout </button>
            </div>
        </div>
        </>

    )
}