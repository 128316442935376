import "./HomePage.css"

export default function HomePage(){
    return (
        <>
        <h2 className="main-text-section-title">
            This is some text about the home page ddsd
        </h2>
        <p className="main-text-section">
            Welcome to my page, this is some text that is going to carry on for a while.
            Please like and comment and subscribe! Don't forget to hit that bell, and turn on notifications.
        </p>
        <p className="main-text-section">
            This is some text about the home page
        </p>
        </>
    )
}