import ImageGalleryAPIComponent from "../components/image_display/ImageGalleryAPIComponent";
import UploadAndDisplayImage from "../components/image_display/UploadAndDisplayImage";
import ImageUploadProvider from "../providers/ImageUploadProvider";

export default function DashboardImagesPage(){

    return (
        <>
        <div>
            <ImageUploadProvider>
                <UploadAndDisplayImage />
                <ImageGalleryAPIComponent />
            </ImageUploadProvider>
        </div>
        </>
    )
}