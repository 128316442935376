import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    // states
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem("site") || "");

    // navigate hook
    const navigate = useNavigate();

    // login aciton
    const loginAction = async (data) => {
        try {

            // login request to api endpoint
            const response = await fetch("https://cfw-database.jonnynaylor92.workers.dev/api/users/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
                
            // get response json
            const res = await response.json();

            console.log("Got repsonse: ", res)

            // if no data in response, throw and error
            if (!res.data) {
                console.log("USER LOGIN FAILED")
                console.log(res.reason)
                throw new Error(res.message);
            }

            // store logged in user and token
            setUser(res.data.user);
            setToken(res.token);
            localStorage.setItem("site", res.token);
            console.log("We got a token bro")

            // navigate to the dashboard page
            navigate("/dashboard");
            
        } catch (err) {
        console.error(err);
        }
    };

    // logout function
    const logOut = () => {

        // clear user and token
        setUser(null);
        setToken("");
        localStorage.removeItem("site");

        // navigate to login page
        navigate("/login");
    };

    return (

        // this provider needs to provide the given states and functions to any children
        // - the token and user states
        // - as well as the login and logout functions
        <AuthContext.Provider value={{
            token, user,
            loginAction, logOut
         }}>
            {children}
        </AuthContext.Provider>
    );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};