import "./WorkPage.css"

export default function WorkPage(){
    return (
        <>
        <div className="centered">
        <h2>
            This page is about work stuff
        </h2>
        <p className="what">
            some text here
        </p>
        <p>
            some more text here
        </p>
        </div>
        </>
    )
}