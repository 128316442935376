import { Link } from "react-router-dom";
import MyDropDownComponent from './MyDropDownComponent';
import MyDropDownProvider from '../../providers/MyDropDownProvider';
// import nav_bar_row from '../../assets/nav_bar_row.png';
import "./MyNavBar.css"
  

export default function MyNavBar(props){
  let app = (
    <MyDropDownProvider>
      <MyDropDownComponent />
    </MyDropDownProvider>
  );

  return (
    <nav className='my-nav'>

      {props.items.map((item) => {
          return <Link className='my-nav-link' to={item.path}> {item.text} </Link>
      })}
      {app}
      <hr style={{width: "70%", marginLeft: 0}}/>
      {/* <img className="nav-bar-row" src={nav_bar_row} alt=""></img> */}
    </nav>
  )
}