import { useState } from "react";
import "./LoginPage.css"
import { useAuth } from "../providers/AuthProvider";

const DEFAULT_USERNAME = "exodus"
const SUGGESTED_USERNAME = "exodus"
const DEFAULT_PASSWORD = "asdf"

function LoginPage(){
  const [input, setInput] = useState({
    username: DEFAULT_USERNAME,
    password: DEFAULT_PASSWORD,
  });

  const auth = useAuth();
  const handleSubmitEvent = (e) => {
    e.preventDefault();
    console.log("Current inputs are: ", input)
    if (input.username !== "" && input.password !== "") {
        
        console.log("try repsonse: ")
        auth.loginAction(input);
        return;
    }
    console.log("pleae provide a valid input")
    alert("pleae provide a valid input");
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
        ...prev,
        [name]: value,
    }));
  };

  return (
    <>
    <h2 className="centered main-text-section-title">
      Welcome
    </h2>
    <div className="form_parent" >
    <form onSubmit={handleSubmitEvent}>
      <div className="form-control">
        <label htmlFor="username">Username:</label>
        <input
          type="email"
          id="username"
          name="username"
          defaultValue={DEFAULT_USERNAME}
          placeholder={SUGGESTED_USERNAME}
          aria-describedby="username"
          aria-invalid="false"
          onChange={handleInput}
        />
        <div id="username" className="sr-only">
          Please enter a valid username. It must contain at least 6 characters.
        </div>
      </div>
      <div className="form-control">
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          aria-describedby="user-password"
          defaultValue={DEFAULT_PASSWORD}
          aria-invalid="false"
          onChange={handleInput}
        />
        <div id="user-password" className="sr-only">
          your password should be more than 6 character
        </div>
      </div>
      <div className="form-control">
      <button className="btn-submit" onClick={handleSubmitEvent}>Submit</button>
      </div>
    </form>
    </div>
    </>
  );
};

export default LoginPage;