import { Route, Routes } from 'react-router-dom';
import MyNavBar from './components/navbar/MyNavBar';
import HomePage from './routes/HomePage';
import WorkPage from './routes/WorkPage';
import LoginPage from './routes/LoginPage';
import AuthProvider from './providers/AuthProvider';
import PrivateRoute from './routes/PrivateRoute';
import DashboardPage from './routes/DashboardPage';
import DashboardImagesPage from './routes/DashboardImagesPage';


function App() {
  const navbar_items = [
    {"path": "/",  
      "text": "Home"},
    {"path": "/work",   
      "text": "Work"},
    // {"path": "/login",   
    //   "text": "Login"},
    {"path": "/dashboard",   
      "text": "Dashboard"},
  ]

  return (
    <div className="App">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <AuthProvider>
        <MyNavBar items={navbar_items} />
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/work" element={<WorkPage/>} />
          <Route path="/login" element={<LoginPage/>} />
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/dashboard/images" element={<DashboardImagesPage />} />
          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
}
export default App;
