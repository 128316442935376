import { createContext, useContext, useState } from "react";

// const MyDropDownContext = createContext({
//     open: false,
//     setOpen: () => {{}}
// });

const MyDropDownContext = createContext();

function MyDropDownProvider({children}) {
    
  
  // we want a useState to represent and set our drop down open state
  const [open, setOpen] = useState(false);

  return (
    <MyDropDownContext.Provider value={{open, setOpen}}>
        {children}
    </MyDropDownContext.Provider>
  )
}

export const useMyDropDownContext = () => useContext(MyDropDownContext)

export default MyDropDownProvider