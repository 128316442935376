import { createContext, useContext, useState } from "react";

const ImageUploadContext = createContext();

function ImageUploadProvider({children}) {
    
  
  // Define a state variable to store the selected image
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const [refresh, setRefresh] = useState(null);

  return (
    <ImageUploadContext.Provider value={{
      selectedImage, setSelectedImage,
      imageBase64, setImageBase64,
      refresh, setRefresh
      }}>
        {children}
    </ImageUploadContext.Provider>
  )
}

export const useImageUploadContext = () => useContext(ImageUploadContext)

export default ImageUploadProvider